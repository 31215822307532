import React from 'react';

const Button = ({ className='', type='standard', disabled=false, children, ...rest }) => {
    // Keep track of hover state.
    const [hover, setHover] = React.useState(false);


    // Styles for type === 'standard'.
    let layoutClass = `py-2 px-6 text-s border`;
    let nonHoverColorClass = `color2-bg color2-text color2-border`;
    let hoverColorClass = `color3-bg color3-border color3-text`;

    // Switch style handling based on button type.
    if (type === 'large') {
        layoutClass = `w-full py-8 mx-auto text-l md:py-6 md:px-12 md:text-2xl`;
        hoverColorClass = `border-2 color3-bg color3-border color3-text`;
        nonHoverColorClass = `border-2 color2-bg color2-border color2-text`;
    } else if (type === 'special') {
        nonHoverColorClass = `border color2-bg color2-text color2-border`;
        hoverColorClass = `color3-bg color3-border color3-text`;
    } else if (type === 'link') {
        layoutClass = `text-s text-white font-light border-none shadow-none bg-transparent`;
        nonHoverColorClass = ``;
        hoverColorClass = ``;
    }

    return <button
        className={`w-fit rounded-md text-center hover:pointer ${layoutClass} ${hover ? hoverColorClass : nonHoverColorClass} ${disabled ? `opacity-50` : ``} ${className}`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        disabled={disabled}
        {...rest}
    >
        {children}
    </button>
};

export default Button;
