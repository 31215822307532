import React from 'react';
import { Icon, NaOption } from 'Components';
import { useDataContext, getRatingIconSize } from "UIDataHandler";
import IconTypeEnum from "Enum/IconTypeEnum.js";

const Rating = ({ questionData }) => {
    const { currentQuestionIndex, form, setForm } = useDataContext();

    const [selected, setSelected] = React.useState(-1);
    const [highlighted, setHighlighted] = React.useState(-1);
    const [numChoices, setNumChoices] = React.useState(questionData.choices.length);

    /**
     * Handle option click.
     */
    const select = (idx) => {
        setSelected(idx === selected ? -1 : idx);
        setHighlighted(-1);
    };

    /**
     * Handle option hover.
     */
    const highlight = (idx) => {
        setHighlighted(idx);
    };

    /**
     * Handle default page state when first viewing the question.
     */
    React.useEffect(() => {
        setNumChoices(questionData.choices.length);
        if (form.answers[currentQuestionIndex].answer !== null) {
            setSelected(questionData.choices.indexOf(form.answers[currentQuestionIndex].answer));
        } else {
            setSelected(-1)
        }
    }, [questionData]);

    React.useEffect(() => {
        form.answers[currentQuestionIndex] = {
            ...form.answers[currentQuestionIndex],
            answer: selected <= -1 ? '' : questionData.choices[selected],
            question: questionData.id,
            isNs: false,
            isNa: selected === -2
        };
        setForm({...form});
    }, [selected]);

    return <React.Fragment>
        <div className={`w-fit mx-auto mt-10 flex flex-row gap-[6px] md:gap-[10px] justify-evenly`} onMouseOut={() => highlight(-1)}>
            {questionData.choices.map((choice, i) => {
                let starNum = i + 1;
                let type = choice;
                if (choice.startsWith(':') && choice.endsWith(':')) {
                    type = choice.startsWith(':stars_') ? IconTypeEnum.STARS : IconTypeEnum[choice.trim(':')];
                }
                const isHighlighted = starNum <= highlighted;
                const isSelected = i <= selected;
                let optionProps = {
                    key: `rating-${starNum}`,
                    className: `flex flex-col md:gap-[3px] text-center hover:pointer ${isSelected ? 'selected' : ''}`,
                    onClick: () => select(i),
                    onMouseOver: () => highlight(starNum)
                };

                const iconProps = {
                    type: type,
                    selected: isSelected || isHighlighted,
                    className: `rating-icon ${isSelected ? 'color3-text' : isHighlighted ? 'color3-text' : 'color2-text'}`,
                    size: getRatingIconSize(numChoices)
                };
                return <React.Fragment key={`rating-${i}`}>
                    <div {...optionProps}>
                        <Icon {...iconProps} />
                        <span className={`rating-option-text ${selected === i ? 'font-bold' : ''}`}>{starNum}</span>
                    </div>
                </React.Fragment>;
            })}
        </div>
        {questionData.includeNa
            ? <NaOption onClick={() => select(-2)} isSelected={form.answers[currentQuestionIndex].isNa} />
            : null
        }
    </React.Fragment>
};

export default Rating;
