import Question from "./Question.js";

/**
 * Survey
 */
const Survey = (data=null) => {
    /**
     * Fields
     */
    const survey = {
        surveyLink: null,
        name: null,
        questions: null
    }

    if (data) {
        survey.surveyLink = data.id;
        survey.name = data.name;
        const questions = [];
        for (let q in data.questions) {
            questions.push(Question(data.questions[q]))
        }
        survey.questions = questions;
    }

    return survey;
};

export default Survey;
