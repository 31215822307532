import React from 'react';
import {
    WelcomePage,
    QuestionPage,
    ThankYouPage,
    ErrorPage,
    LoadingPage
} from 'Pages';
import { DataContext } from "UIDataHandler";
import actions from 'actions';
import { useForm } from "Form";
import { PerformedSurvey, Answer } from "Models";
import { useParams, useSearchParams } from "react-router-dom";

const FeedbackSurvey = ({}) => {
    const { feedbackSurveyLinkId } = useParams();
    const [searchParams] = useSearchParams();

    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(-1);
    const [isKiosk, setIsKiosk] = React.useState(false);
    const [surveyData, setSurveyData] = React.useState(null);
    const [numQuestions, setNumQuestions] = React.useState(null);
    const [surveyError, setSurveyError] = React.useState(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const currentQuestion = surveyData?.questions?.[currentQuestionIndex];

    const goToNextQuestion = () => {
        if (currentQuestionIndex < numQuestions) {
            setCurrentQuestionIndex(prev => prev + 1);
        } else {
            setCurrentQuestionIndex(-1);
        }
    };

    const goToPreviousQuestion = () => {
        if (currentQuestionIndex > -1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const finishSurvey = () => {
        setCurrentQuestionIndex(numQuestions);
    }

    const useOnSubmit = () => {
        setIsSubmitting(true);
        actions.postPerformedSurvey(form)
            .then(() => finishSurvey())
            .catch((e) => setSurveyError(e.message))
            .finally(() => setIsSubmitting(false));
    };

    const getDefaultFormState = () => {
        let form = PerformedSurvey();
        if (surveyData) {
            for (let i = 0; i < surveyData.questions.length; i++) {
                form.answers.push(Answer());
            }
            form.surveyLink = surveyData.surveyLink;
        }
        return form;
    }

    const { form, setForm, handleSubmit } = useForm({
        onSubmit: useOnSubmit,
        initialValues: []
    });

    React.useEffect(async () => {
        if (feedbackSurveyLinkId) {
            await actions.getSurvey(feedbackSurveyLinkId)
                .then(survey => setSurveyData(survey))
                .catch(error => setSurveyError(error.message));
        } else {
            setSurveyError('Survey link uuid not provided.');
        }
    }, [feedbackSurveyLinkId])

    React.useEffect(async () => {
        if (searchParams.get('kiosk') && searchParams.get('kiosk') === '1') {
            setIsKiosk(true);
        }
    }, [searchParams]);

    React.useEffect(() => {
        if (surveyData) {
            setNumQuestions(surveyData.questions.length);
            setForm(getDefaultFormState());
        }
    }, [surveyData]);

    return <DataContext.Provider value={
        {
            form: form,
            setForm: setForm,
            handleSubmit: handleSubmit,
            surveyData: surveyData,
            currentQuestionIndex: currentQuestionIndex,
            currentQuestion: currentQuestion,
            goToNextQuestion: goToNextQuestion,
            goToPreviousQuestion: goToPreviousQuestion,
            numQuestions: numQuestions,
            getDefaultFormState: getDefaultFormState,
            isSubmitting: isSubmitting
        }
    }>
        {surveyError
            ? <ErrorPage />
            : surveyData === null
                ? <LoadingPage />
                : currentQuestionIndex === -1
                    ? <WelcomePage text={surveyData.name} />
                    : currentQuestionIndex === numQuestions
                        ? <ThankYouPage isKiosk={isKiosk} />
                        : currentQuestion !== null
                            ? <QuestionPage />
                            : <LoadingPage />
        }
    </DataContext.Provider>;
};

export default FeedbackSurvey;
