import React from "react";
import { useDataContext } from "UIDataHandler";
import { Button } from "./index.js";

const ActionBar = ({ questionData, className='' }) => {
    const {
        currentQuestionIndex,
        goToNextQuestion,
        goToPreviousQuestion,
        currentQuestion,
        numQuestions,
        surveyData,
        form,
        setForm,
        handleSubmit,
        isSubmitting
    } = useDataContext();

    const [isFirst, setIsFirst] = React.useState(currentQuestionIndex === 0);
    const [isLast, setIsLast] = React.useState(currentQuestionIndex === numQuestions - 1);
    const [showSkipButton, setShowSkipButton] = React.useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);

    const isNa = currentQuestion.includeNa && form.answers[currentQuestionIndex].isNa;

    const updateNextButtonDisabledState = () => {
        if (currentQuestion) {
            setNextButtonDisabled(
                currentQuestion.includeNs === false &&
                (!form.answers[currentQuestionIndex].answer && !form.answers[currentQuestionIndex].isNs && !isNa)
            );
        }
    };

    const getHasTempAnswer = () => {
        return !!form.answers[currentQuestionIndex].answer || form.answers[currentQuestionIndex].isNa;
    }

    const skipQuestion = () => {
        form.answers[currentQuestionIndex] = {
            question: surveyData.questions[currentQuestionIndex].id,
            answer: '',
            isNs: true,
            isNa: false
        };
        setForm({...form});
    }

    const handleSkipButton = () => {
        skipQuestion();
        if (isLast) {
            handleSubmit();
        } else {
            goToNextQuestion();
        }
    };

    const useHandleSubmit = () => {
        if (showSkipButton && !getHasTempAnswer()) {
            skipQuestion();
        }
        handleSubmit();
    }

    React.useEffect(() => {
        setIsFirst(currentQuestionIndex === 0);
        setIsLast(currentQuestionIndex === numQuestions - 1);
        updateNextButtonDisabledState();
        setShowSkipButton(questionData.includeNs === true);
    }, [questionData]);

    React.useEffect(() => {
        updateNextButtonDisabledState();
    }, [form]);

    return <div id={'feedback-action-bar'} className={`flex-none flex flex-row justify-between`}>
        <Button
            onClick={goToPreviousQuestion}
            type={isFirst ? `special` : `standard`}
            className={`flex-none`}
        >
            {isFirst ? 'Exit' : 'Back'}
        </Button>
        {showSkipButton ? <Button onClick={handleSkipButton} type={`link`} className={`flex-none bg-transparent skip-button color1-text`}>Skip{isLast ? ' & Submit' : ''}</Button> : <></>}
        <Button
            onClick={isLast ? useHandleSubmit : (showSkipButton && !getHasTempAnswer()) ? handleSkipButton : goToNextQuestion }
            type={isLast ? `special` : `standard`}
            className={`flex-none`}
            disabled={nextButtonDisabled || isSubmitting}
        >
            {isLast ? 'Finish' : 'Next'}
        </Button>
    </div>;
};

export default ActionBar;
