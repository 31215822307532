import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { FeedbackSurvey, ErrorPage, LoadingPage, FrontPage } from "Pages";

const router = createBrowserRouter([
    {
        path: '/:feedbackSurveyLinkId',
        element: <FeedbackSurvey />
    },
    {
        path: '/',
        element: <FrontPage />
    },
    {
        path: '*',
        element: <ErrorPage />
    }
])

const SentactFeedback = ({}) => {
    return <div className={'h-screen flex justify-center no-scrollbar'}>
        <RouterProvider router={router} fallbackElement={<LoadingPage />} />
    </div>;
};


document.addEventListener("DOMContentLoaded", (e) => {
    const customer = window.location.hostname.split('.')[0];

    const themeElement = document.createElement('link');
    themeElement.type = 'text/css';
    themeElement.rel = 'stylesheet';
    themeElement.href = `./themes/${customer}/theme.css`;
    document.head.appendChild(themeElement);

    ReactDOM.render(
        <SentactFeedback />,
        document.getElementById('root')
    );
});
