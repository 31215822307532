import React from 'react';
import { Page, SentactFeedbackStamp } from "Components";
import CustomerLogo from "Images/CustomerLogo.jsx";

const FrontPage = () => {
    return <Page className={`flex flex-col h-full items-center text-center justify-around color1-text`}>
        <div className={`p-3 logo-container`}>{<CustomerLogo />}</div>
        <div className={`flex flex-col gap-16 text-center`}>
            <p className={`text-2xl md:text-4xl w-full m-auto font-medium`}>
                Sentact’s digital, enterprise-wide rounding solutions help hospitals to&nbsp;
                <a className="underline underline-offset-4 color1-border">create safer environments</a>,&nbsp;
                <a className="underline underline-offset-4 color1-border">increase patient engagement</a>,&nbsp;
                and <a className="underline underline-offset-4 color1-border">improve overall quality of care</a>.
            </p>
            <a href={`https://www.sentact.com`} className={`text-3xl md:text-5xl`}>Learn More ↗</a>
        </div>
        <SentactFeedbackStamp />
    </Page>
};

export default FrontPage;
