import React from 'react';

const Page = ({ className='', children }) => {
    return <div
        className={`
            relative
            w-11/12
            m-auto
            py-2
            md:max-w-6xl
            feedback-page ${className}
        `}
    >
        {children}
    </div>
};

export default Page;
