import _ from "lodash";

/**
 * Question
 */
const Question = (data={}) => {
    /**
     * Fields
     */
    const fields = {
        id: null,
        text: null,
        type: null,
        choices: null,
        includeNa: null,
        includeNs: null
    };

    return _.merge(fields, data);
};

export default Question;
