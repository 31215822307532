import React from 'react';
import constants from 'Constants';

/**
 * Define React.Context wrapper to expose top-level variables to all children a via useContext() hook.
 */
export const DataContext = React.createContext(null);
export const useDataContext = () => React.useContext(DataContext);

/**
 * Returns content div width of a QuestionBox based on `w-11/12` { width: 91.66667%; } property in Page component and
 * `p-8` { padding: 32px; } property in QuestionBox content div in pixels.
 */
export const getQuestionBoxContentWidth = () => {
    return (window.innerWidth * .92) - 64;
};

/**
 * Determines proper size for each individual rating icon by calculating QuestionBox content width minus gap size
 * between options, all divided by total number of choices. Caps max size at 50px.
 */
export const getRatingIconSize = (numChoices) => {
    return Math.min(((getQuestionBoxContentWidth() - (getRatingIconGap() * (numChoices - 1))) / numChoices), 50);
};

/**
 * Get gap size between rating icons in px.
 */
export const getRatingIconGap = () => {
    return window.innerWidth >= constants.screens.md ? 10 : 6;
};
