import React from 'react';
import {useDataContext} from "UIDataHandler";
import { NaOption } from "Components";

const Text = ({ questionData }) => {
    const { form, setForm, currentQuestionIndex } = useDataContext();

    const updateAnswerInForm = (answer) => {
        form.answers[currentQuestionIndex] = {
            ...form.answers[currentQuestionIndex],
            answer: answer,
            question: questionData.id,
            isNs: false,
            isNa: false
        };
        setForm({...form});
    }

    const selectNa = () => {
        form.answers[currentQuestionIndex] = {
            ...form.answers[currentQuestionIndex],
            answer: '',
            question: questionData.id,
            isNa: !form.answers[currentQuestionIndex].isNa,
            isNs: false
        };
        setForm({...form});
    }

    return <div className={`m-auto w-fit`}>
        <textarea
            className={`relative rounded-md p-2.5 border color1-border h-[150px] w-full md:w-[600px] text-sm m-auto`}
            value={form.answers[currentQuestionIndex].answer ?? ''}
            onChange={(e) => updateAnswerInForm(e.target.value)}
        />
        {questionData.includeNa
            ? <NaOption onClick={selectNa} isSelected={form.answers[currentQuestionIndex].isNa} />
            : null
        }
    </div>;
};

export default Text;
