import React from 'react';
import {
    QuestionPageHeader,
    QuestionBox,
    ProgressBar,
    ActionBar,
    AnswerType
} from "Components";
import { Page } from "Components";
import { useDataContext } from "UIDataHandler";

const QuestionPage = ({}) => {
    const { currentQuestionIndex, currentQuestion } = useDataContext();

    return <Page className={'flex flex-col h-[95%] md:h-4/6 md:min-h-[700px] md:my-auto justify-around'}>
        <QuestionPageHeader />
        <QuestionBox title={'Question #' + (currentQuestionIndex + 1)}>
            <div className={`mt-5 mb-12 text-center ${currentQuestion.text.length > 50 ? 'text-base md:text-xl' : 'text-2xl'}`}>{currentQuestion.text}</div>
            <AnswerType questionData={currentQuestion} />
        </QuestionBox>
        <ActionBar questionData={currentQuestion} />
        <ProgressBar />
    </Page>;
};

export default QuestionPage;
