import React from 'react';
import { Page, SentactFeedbackStamp } from "Components";
import CustomerLogo from "Images/CustomerLogo.jsx";

const ErrorPage = ({ text=`Something went wrong. Check your survey link and try again.` }) => {
    return <Page className={`flex flex-col h-full items-center text-center justify-around color1-text`}>
        <div className={`p-3 logo-container`}>{<CustomerLogo />}</div>
        <div className={`flex flex-col gap-16 text-center`}>
            <h1 className={`text-3xl md:text-4xl w-full m-auto`}>Survey Not Found</h1>
                <div className={`flex-none text-xl w-fit mx-auto py-1 px-3 rounded-sm error-message font-light`}>{text}</div>
                <div className={`flex-none text-wrap mx-auto font-light text-sm`}>
                    <hr className={`w-6 mx-auto pb-3`} />
                    <div className={`w-10/12 md:w-7/12 mx-auto error-message-alt`}>
                        If you believe this is incorrect, please reach out to your Sentact admin for resolution.
                    </div>
                </div>
        </div>
        <SentactFeedbackStamp />
    </Page>
};

export default ErrorPage;
