const IconTypeEnum = {
    STARS: 'stars',
    FACE_HAPPY: 'face_smile',
    FACE_VERY_HAPPY: 'face_laughing',
    FACE_NEUTRAL: 'face_neutral',
    FACE_UNHAPPY: 'face_frown',
    FACE_VERY_UNHAPPY: 'face_angry',
    EXIT: 'exit'
}

export default IconTypeEnum;
