import React from 'react';
import { Icon } from "./index.js";

const NaOption = ({ onClick, isSelected }) => {
    const [hover, setHover] = React.useState(false);

    return <div
        className={`max-w-max h-[50px] mt-6 mx-auto text-center hover:pointer ${isSelected ? 'selected' : ''}`}
        onClick={onClick}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
    >
        <Icon
            type={'Not Applicable'}
            selected={isSelected}
            className={`
                p-3 mt-1 border border-solid rounded-md cursor-pointer
                ${isSelected 
                    ? 'color1-bg color1-border color1-text' 
                    : `${hover ? 'color3-bg color3-border color3-text' : 'color2-bg color2-border color2-text'}`}
            `}
        />
    </div>;
};

export default NaOption;
