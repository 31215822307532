import _ from "lodash";

/**
 * Answer
 */
const Answer = (data={}) => {
    /**
     * Fields
     */
    const fields = {
        question: null,
        answer: null,
        isNa: null,
        isNs: null
    };

    return _.merge(fields, data);
};

export default Answer;
