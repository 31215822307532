import Answer from "./Answer.js";

/**
 * Performed Survey
 */
const PerformedSurvey = (data=null) => {
    /**
     * Fields
     */
    const performedSurvey = {
        surveyLink: null,
        answers: []
    }

    if (data) {
        performedSurvey.surveyLink = data.surveyLink;
        for (let a in data.answers) {
            performedSurvey.answers.push(Answer(data.answers[a]));
        }
    }

    return performedSurvey;
};

export default PerformedSurvey;
