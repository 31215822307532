import React from 'react';
import { MultipleChoiceOption, NaOption, Icon, iconTextDictionary } from "Components";
import { useDataContext } from "UIDataHandler";

const MultipleChoice = ({ questionData, className='' }) => {
    const { currentQuestionIndex, form, setForm } = useDataContext();

    const [selectedIdx, setSelectedIdx] = React.useState(-1);
    const [hasRectangle, setHasRectangle] = React.useState(false);
    const [isIcons, setIsIcons] = React.useState(false);

    React.useEffect(() => {
        setSelectedIdx(-1);
        questionData.choices.some((choice) => {
            if (choice.startsWith(':') && choice.endsWith(':')) {
                setIsIcons(true);
                setHasRectangle(false);
            } else {
                setIsIcons(false);
                if (choice.length > 12) {
                    setHasRectangle(true);
                } else {
                    setHasRectangle(false);
                }
            }
        });
        if (form.answers[currentQuestionIndex].answer !== null) {
            setSelectedIdx(questionData.choices.indexOf(form.answers[currentQuestionIndex].answer));
        }
    }, [currentQuestionIndex]);

    React.useEffect(() => {
        form.answers[currentQuestionIndex] = {
            ...form.answers[currentQuestionIndex],
            answer: selectedIdx <= -1 ? '' : questionData.choices[selectedIdx],
            question: questionData.id,
            isNs: false,
            isNa: selectedIdx === -2
        };
        setForm({...form});
    }, [selectedIdx]);

    const select = (idx) => {
        setSelectedIdx(idx === selectedIdx ? -1 : idx);
    };

    const renderChoice = (choice, i) => {
        let cardStyle = `w-[120px] h-[120px] md:w-[135px] md:h-[135px] justify-evenly`;
        if (isIcons) {
            cardStyle = `w-full justify-start md:flex-col md:w-[135px] md:h-[135px] md:justify-evenly md:text-center`;
        } else if (hasRectangle) {
            cardStyle = `w-full justify-start text-left`;
        }

        return <MultipleChoiceOption
            key={'mc-' + i}
            className={`flex-initial border-2 ${cardStyle} ${i === selectedIdx ? `color1-bg color1-border color1-text` : `color2-bg color2-border color2-text`}`}
            selected={i === selectedIdx}
            onClick={() => select(i)}
        >
            {(isHover) => (isIcons
                ? <div className={`flex flex-row items-center md:flex-col md:gap-[10px]`}>
                    <Icon
                        type={choice.slice(1, -1)}
                        selected={i === selectedIdx}
                        className={`icon h-[35px] w-[35px] md:h-[45px] md:w-[45px] flex-none md:mx-auto md:mb-2 ${i === selectedIdx ? 'color1-text' : isHover ? `color3-text` : 'color2-text'}`}
                    />
                    <div className={`icon-text flex-none mx-auto px-[50px] md:px-0 ${i === selectedIdx ? 'color1-text' : isHover ? `color3-text` : 'color2-text'}`}>{iconTextDictionary[choice.slice(1, -1)] || ''}</div>
                </div>
                : <div className={i === selectedIdx ? 'color1-text' : isHover ? `color3-text` : 'color2-text'}>{choice}</div>
            )}
        </MultipleChoiceOption>;
    };

    return <React.Fragment>
        <div
            className={
                `m-auto gap-[10px]
                ${isIcons 
                    ? `w-11/12 flex flex-col md:flex-row md:w-fit` 
                    : `w-fit 
                       ${hasRectangle 
                            ? 'grid grid-cols-1 md:grid-cols-2' 
                            : `grid grid-cols-2 md:flex md:flex-row md:justify-evenly`
                       }`
                }
                ${className}`
            }
        >
            {questionData.choices.map((choice, i) => renderChoice(choice, i))}
        </div>
        {questionData.includeNa
            ? <NaOption onClick={() => select(-2)} isSelected={form.answers[currentQuestionIndex].isNa} />
            : null
        }
        </React.Fragment>;
};

export default MultipleChoice;
