import React from 'react';
import { Page, Button } from "Components";
import { useDataContext } from "UIDataHandler";
import { HandThumbsUpFill } from "react-bootstrap-icons";

const ThankYouPage = ({ isKiosk=false }) => {
    const { goToNextQuestion } = useDataContext();

    React.useEffect(() => {
        if (isKiosk) {
            setTimeout(() => {
                window.location.reload(true);
            }, 60000);
        }
    }, [])

    return <Page className={`flex flex-col gap-[55px] items-center justify-center text-center color1-text`}>
        <h1 className={`text-3xl md:text-5xl flex-none w-full m-auto`}>{`Thank You For Your Feedback!`}</h1>
        {isKiosk
            ? <Button onClick={goToNextQuestion} type={'large'} className={`m-auto w-10/12 md:w-1/3 special-button`}>Home</Button>
            : <HandThumbsUpFill className={`m-auto`} height={'45px'} width={'45px'} />
        }
    </Page>
};

export default ThankYouPage;
