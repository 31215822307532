import React from 'react';

/**
 * Manage form state.
 *
 * Validations should follow the structure of the form with the following format:
 *  Keys can contain either an object with required: true or a custom isValid function. Example:
 *      validations: {
 *          rootKey1: {
 *              subKey1: {
 *                  required: true
 *              },
 *              subKey2: {
 *                  isValid: () => return true if valid else false
 *              }
 *          }
 *      }
 *
 *  You may also provide a special key `custom` which is an array of custom conditions. If you provide an object with
 *  an array of keys paired with an isValid callback to custom, if any of the keys in the array are valid, all keys in
 *  the array are valid. Please note that these keys must be all located at the same depth and that the `custom` key
 *  must exist in that same depth. Example of a 'one of' situation:
 *      validations: {
 *          rootKey1: {
 *              custom: [
 *                  {keys: ['subKey1', 'subKey2'], isValid: () => return true if valid else false}
 *              ]
 *          }
 *      }
 *
 *  Conditional blocks work such that any validations contained within a `conditions` key will only be run if the
 *  willValidate function provided evaluates to true. Otherwise these validations are not processed. As with `custom`,
 *  `conditions` must exist at the proper depth for the keys it encompasses. Example:
 *      validations: {
 *          rootKey1: {
 *              conditions: [
 *                  {
 *                      willValidate: () => return true to run validations else false,
 *                      validations: {
 *                          subKey1: {
 *                              required: true
 *                          },
 *                          subKey2: {
 *                              isValid: () => return true if valid else false
 *                          }
 *                      }
 *                  }
 *              ]
 *          }
 *      }
 *
 * @param {Object}   [options]
 * @param {function} [options.onSubmit]       Form submit action.
 * @param {Object}   [options.initialValues]  Initial values for the form state.
 * @param {Object}   [options.validations]    Validations for required fields.
 *
 * @author Oliver Terrell <oterrell@sentact.com>
 */
export const useForm = (options) => {
    const [form, setForm] = React.useState(options.initialValues || {})
    const [errors, setErrors] = React.useState({})
    const [isInValidationMode, setIsInValidationMode] = React.useState(false)
    let validations = options.validations || {}

    const setValidations = (newValidations) => {
        validations = newValidations;
    }

    React.useEffect(() => {
        if (Object.keys(errors).length > 0) {
            setIsInValidationMode(true)
        }
    }, [errors])

    React.useEffect(() => {
        if (isInValidationMode) {
            setErrors({...validate(form, validations)})
        }
    }, [form])

    const checkField = (value, validation) => {
        return (
            validation.isValid?.() === false ||
            validation.required && (
                typeof value === 'undefined' ||
                value === null ||
                value === ''
            )
        )
    }

    const validate = (form, validations) => {
        let errors = {}
        for (let key in validations) {
            if (typeof validations[key] === 'object') {
                if (key === 'conditions') {
                    validations[key].forEach((condition) => {
                        if (condition.willValidate?.() === true) {
                            for (let validation in condition.validations) {
                                let conditionalErrors = validate(form, condition.validations)
                                if (Object.keys(conditionalErrors).length > 0) {
                                    errors = Object.assign({}, errors || {}, conditionalErrors)
                                }
                            }
                        }
                    })
                } else if (key === 'custom') {
                    validations[key].forEach((condition) => {
                        if (condition.isValid() === false) {
                            condition.keys.forEach((key) => {
                                errors[key] = true
                            })
                        }
                    })
                } else if (validations[key].required || validations[key].isValid) {
                    if (checkField(form[key], validations[key])) {
                        errors[key] = true
                    }
                } else {
                    let nestedErrors = validate(form[key], validations[key])
                    if (Object.keys(nestedErrors).length > 0) {
                        errors[key] = Object.assign({}, errors[key] || {}, nestedErrors)
                    }
                }
            }
        }
        return errors
    }

    const handleSubmit = () => {
        let newErrors = validate(form, validations)

        if (Object.keys(newErrors).length > 0) {
            setErrors({...newErrors})
            return
        }

        if (options.onSubmit) {
            options.onSubmit()
        }
    }

    return {
        form,
        setForm,
        handleSubmit,
        errors,
        setErrors,
        checkField,
        validate,
        validations,
        setValidations
    }
}
