import React from 'react';

const QuestionBox = ({title='Question #n', children}) => {
    return <div className={`flex-none rounded-lg overflow-auto`}>
        <div className={`py-5 px-9 text-xl text-center md:text-start color2-bg`}>{title}</div>
        <div className={`p-8 flex flex-col flex-start align-middle bg-white h-[28rem] overflow-scroll no-scrollbar`}>
            <div className={`my-auto`}>{children}</div>
        </div>
    </div>
};

export default QuestionBox;
