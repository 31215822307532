import React from 'react';
import { useDataContext } from "UIDataHandler";
import { Icon } from "Components";
import constants from 'Constants';
import IconTypeEnum from "Enum/IconTypeEnum.js";

const QuestionPageHeader = ({}) => {
    const { surveyData } = useDataContext();

    return <div className={`relative w-full text-2xl md:text-3xl color1-text`}>
        <div className={`w-full text-left mr-[3px] md:text-center question-header`}>{surveyData.name}</div>
        <div onClick={() => window.location.reload()} className={`absolute top-[5px] right-0 text-base md:flex md:flex-row md:gap-[10px] hover:cursor-pointer exit-button`}>
            <Icon type={IconTypeEnum.EXIT} size={25} />
            {window.innerWidth >= constants.screens.md ? <span>Exit</span> : <></>}
        </div>
    </div>
};

export default QuestionPageHeader;
