import React from 'react';
import { Page, Button, SentactFeedbackStamp } from "Components";
import { useDataContext } from "UIDataHandler";
import CustomerLogo from 'Images/CustomerLogo.jsx';

const WelcomePage = ({ text='Hello and welcome.' }) => {
    const { goToNextQuestion, setForm, getDefaultFormState } = useDataContext();

    React.useEffect(() => {
        setForm(getDefaultFormState());
    }, []);

    return <Page className={`flex flex-col h-full items-center justify-around welcome-container text-white`}>
        <CustomerLogo />
        <div className={`flex flex-col gap-11 text-center`}>
            <h1 className={`text-3xl md:text-5xl flex-none w-full m-auto color1-text`}>{text}</h1>
            <Button onClick={goToNextQuestion} type={`large`} className={`m-auto`}>
                Launch Survey
            </Button>
        </div>
        <SentactFeedbackStamp />
    </Page>
};

export default WelcomePage;
