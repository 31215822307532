import React from 'react';

const MultipleChoiceOption = ({className='', onClick=()=>null, selected=false, children}) => {
    const [isHover, setIsHover] = React.useState(false);

    return <div
        className={
            `flex flex-col justify-around align-center text-center p-2.5 rounded-md text-black
            border box-border hover:cursor-pointer ${isHover && !selected ? `color3-bg color3-text color3-border` : ``}
            ${className}`
        }
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
    >
        {children(isHover)}
    </div>
};

export default MultipleChoiceOption;
