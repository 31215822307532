import React from 'react';
import { MultipleChoice, Rating, Text } from "./index.js";
import { default as AnswerTypeEnum } from "Enum/AnswerTypeEnum.js";

const AnswerType = ({ questionData, ...rest }) => {
    switch (questionData.type) {
        case AnswerTypeEnum.YES_NO:
        case AnswerTypeEnum.MULTIPLE_CHOICE:
            return questionData.choices.some(choice => choice.includes(':star_'))
                ? <Rating questionData={questionData} />
                : <MultipleChoice questionData={questionData} {...rest} />;
        case AnswerTypeEnum.TEXT:
            return <Text questionData={questionData} />;
        default:
            return <></>;
    }
};

export default AnswerType;
