import base from "api/action/base.js";

const postPerformedSurvey = (
    data,
    params={},
    cancelToken=null
) => {
    delete data.name;
    return base.post(
        base.publicApiBaseUrl + 'performed-surveys',
        data,
        params,
        cancelToken
    )
        .then(response => response)
        .catch(error => throw new Error(error));
}

export default postPerformedSurvey;
