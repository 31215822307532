import React from 'react';
import { useDataContext } from "UIDataHandler";

const ProgressBar =({ className='' }) => {
    const { currentQuestionIndex, numQuestions } = useDataContext();

    const questionBlocks = Array(numQuestions).fill(0)

    return <div className={`flex flex-row h-2.5 rounded-lg overflow-hidden bg-white opacity-60 ${className}`}>
        {questionBlocks.map((_, idx) => {
            return <div key={`progress-${idx}`} className={`flex-1 ${idx <= currentQuestionIndex ? 'color3-bg color3-border' : ''}`}>
            </div>;
        })}
    </div>
};

export default ProgressBar;
