import base from "api/action/base.js";
import { Survey } from "Models";

const getSurvey = (
    id,
    params={},
    cancelToken=null
) => {
    return base.get(
        base.publicApiBaseUrl + 'survey-links/' + id,
        params,
        cancelToken
    )
        .then(data => Survey(data))
        .catch(error => {
            throw new Error(error);
        });
}

export default getSurvey;
