import React from 'react';

export const SentactFeedbackLogo = ({}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="206.677" height="31.377" viewBox="0 0 206.677 31.377">
        <g id="Layer_1" data-name="Layer 1" transform="translate(0.02)">
            <g id="Group_54" data-name="Group 54" transform="translate(-0.02)">
                <rect id="Rectangle_84" data-name="Rectangle 84" width="8.059" height="8.059" transform="translate(0.118 23.318)" fill="#fff"/>
                <rect id="Rectangle_85" data-name="Rectangle 85" width="8.059" height="8.059" transform="translate(11.335 23.318)" fill="#fff"/>
                <rect id="Rectangle_86" data-name="Rectangle 86" width="8.059" height="8.059" transform="translate(22.554 23.318)" fill="#fff"/>
                <rect id="Rectangle_87" data-name="Rectangle 87" width="8.059" height="8.059" transform="translate(33.771 23.318)" fill="#fff"/>
                <rect id="Rectangle_88" data-name="Rectangle 88" width="8.059" height="8.059" transform="translate(44.99 23.318)" fill="#fff"/>
                <path id="Path_19" data-name="Path 19" d="M1.643,43.785a4.135,4.135,0,0,0,1.375,2.572,4.66,4.66,0,0,0,2.845.748,4.93,4.93,0,0,0,1.552-.217A3.1,3.1,0,0,0,8.491,46.3a2.516,2.516,0,0,0,.64-.83,2.276,2.276,0,0,0,.217-.98,1.807,1.807,0,0,0-.666-1.539,5.055,5.055,0,0,0-1.674-.789,21.306,21.306,0,0,0-2.179-.49,10.577,10.577,0,0,1-2.179-.613,4.375,4.375,0,0,1-1.674-1.17,3.157,3.157,0,0,1-.666-2.152,3.678,3.678,0,0,1,1.293-2.9A5.392,5.392,0,0,1,5.265,33.71a6.135,6.135,0,0,1,3.716,1.021,4.669,4.669,0,0,1,1.73,3.417H9.05a3.057,3.057,0,0,0-1.238-2.3A4.618,4.618,0,0,0,5.24,35.18a3.673,3.673,0,0,0-2.369.666A2.157,2.157,0,0,0,2.053,37.6a1.716,1.716,0,0,0,.666,1.457,5.461,5.461,0,0,0,1.674.818q1.007.314,2.179.558a10.811,10.811,0,0,1,2.179.695,4.778,4.778,0,0,1,1.674,1.211,3.122,3.122,0,0,1,.666,2.123,3.569,3.569,0,0,1-.436,1.783,3.789,3.789,0,0,1-1.2,1.293,5.6,5.6,0,0,1-1.81.777,9.646,9.646,0,0,1-2.3.258,6.238,6.238,0,0,1-2.219-.367,4.843,4.843,0,0,1-1.648-1.021A4.705,4.705,0,0,1,.416,45.663,5.346,5.346,0,0,1-.02,43.784H1.64Z" transform="translate(0.02 -28.4)" fill="#fff"/>
                <path id="Path_20" data-name="Path 20" d="M87.3,41.525a7.637,7.637,0,0,0,.3,2.164,5.237,5.237,0,0,0,.885,1.771,4.308,4.308,0,0,0,1.484,1.2,4.941,4.941,0,0,0,4.82-.4,4.068,4.068,0,0,0,1.525-2.4H98.14a8.862,8.862,0,0,1-.789,1.865,5.754,5.754,0,0,1-1.226,1.5,5.3,5.3,0,0,1-1.742.994,7.05,7.05,0,0,1-2.342.354,6.84,6.84,0,0,1-2.914-.572,5.48,5.48,0,0,1-2.015-1.566,6.6,6.6,0,0,1-1.17-2.342,10.572,10.572,0,0,1-.381-2.9,9.256,9.256,0,0,1,.449-2.914A7.424,7.424,0,0,1,87.288,35.9,5.95,5.95,0,0,1,89.317,34.3a6.118,6.118,0,0,1,2.724-.586,5.623,5.623,0,0,1,4.574,2,8.8,8.8,0,0,1,1.634,5.813H87.3Zm9.2-1.361a6.474,6.474,0,0,0-.285-1.947,4.57,4.57,0,0,0-.844-1.58,4.02,4.02,0,0,0-1.4-1.062,4.512,4.512,0,0,0-1.933-.4,4.3,4.3,0,0,0-1.92.408A4.413,4.413,0,0,0,88.7,36.676a5.427,5.427,0,0,0-.939,1.58,7.307,7.307,0,0,0-.463,1.906h9.2Z" transform="translate(-72.079 -28.4)" fill="#fff"/>
                <path id="Path_21" data-name="Path 21" d="M187.273,48.2H185.64v-14.1h1.634v1.961a10.39,10.39,0,0,1,2.029-1.689,4.659,4.659,0,0,1,2.519-.654,6.43,6.43,0,0,1,2.423.449,4.146,4.146,0,0,1,1.879,1.566,3.526,3.526,0,0,1,.613,1.566,14.323,14.323,0,0,1,.095,1.674V48.2H195.2v-9.2a4.208,4.208,0,0,0-.844-2.859,3.586,3.586,0,0,0-2.8-.953,3.979,3.979,0,0,0-1.389.232,4.381,4.381,0,0,0-1.129.613,4.116,4.116,0,0,0-.857.871,4.028,4.028,0,0,0-.545,1.035,5.172,5.172,0,0,0-.3,1.307q-.054.626-.055,1.361v7.6Z" transform="translate(-156.394 -28.4)" fill="#fff"/>
                <path id="Path_22" data-name="Path 22" d="M278.44,29.32q-.3.054-.789.191a4.038,4.038,0,0,1-1.088.135,2.6,2.6,0,0,1-1.865-.613,2.979,2.979,0,0,1-.64-2.191V16.523H272.07V15.162h1.988V11.24h1.634v3.921h2.642v1.361h-2.642v9.666q0,.517.027.9a1.485,1.485,0,0,0,.164.627.781.781,0,0,0,.422.354,2.345,2.345,0,0,0,.8.109,4.044,4.044,0,0,0,.666-.055c.227-.036.449-.082.666-.135v1.334Z" transform="translate(-229.21 -9.469)" fill="#fff"/>
                <path id="Path_23" data-name="Path 23" d="M331.1,39.973q.463-.054,1.1-.164a4.11,4.11,0,0,0,1.048-.3.868.868,0,0,0,.5-.64,4.05,4.05,0,0,0,.095-.83,2.784,2.784,0,0,0-.8-2.056,3.746,3.746,0,0,0-2.709-.8,7.54,7.54,0,0,0-1.552.15,3.366,3.366,0,0,0-1.238.517,2.858,2.858,0,0,0-.871.98,4.158,4.158,0,0,0-.449,1.539h-1.66a5.07,5.07,0,0,1,.572-2.138,4.322,4.322,0,0,1,1.266-1.443,5.273,5.273,0,0,1,1.8-.818,8.819,8.819,0,0,1,2.164-.258,8.332,8.332,0,0,1,2,.232,4.641,4.641,0,0,1,1.634.736,3.412,3.412,0,0,1,1.088,1.348,4.866,4.866,0,0,1,.4,2.07v7.678c0,.635.113,1.04.34,1.211s.685.132,1.375-.123v1.279c-.128.036-.313.091-.558.164a2.538,2.538,0,0,1-.721.109,3.221,3.221,0,0,1-.736-.082,1.265,1.265,0,0,1-.654-.258,1.6,1.6,0,0,1-.4-.49,2.037,2.037,0,0,1-.2-.627,4.577,4.577,0,0,1-.055-.721,7.078,7.078,0,0,1-2.369,1.742,6.977,6.977,0,0,1-2.886.6,6.44,6.44,0,0,1-1.783-.246,4.312,4.312,0,0,1-1.511-.762,3.774,3.774,0,0,1-1.035-1.266,3.873,3.873,0,0,1-.381-1.783q0-3.594,4.929-4.247l2.26-.3Zm2.75.818a8.066,8.066,0,0,1-2.1.572q-1.061.137-2.123.217a5.982,5.982,0,0,0-2.927.844,2.419,2.419,0,0,0-1.048,2.152,2.284,2.284,0,0,0,.939,1.919,2.86,2.86,0,0,0,.98.463,4.362,4.362,0,0,0,1.129.15,7.4,7.4,0,0,0,1.906-.246,5.178,5.178,0,0,0,1.648-.748,3.84,3.84,0,0,0,1.158-1.279A3.7,3.7,0,0,0,333.851,43V40.791Z" transform="translate(-272.884 -28.4)" fill="#fff"/>
                <path id="Path_24" data-name="Path 24" d="M430.167,38.53a4.732,4.732,0,0,0-1.5-2.464,4.072,4.072,0,0,0-2.7-.885,4.262,4.262,0,0,0-2.152.517,4.357,4.357,0,0,0-1.47,1.375,6.187,6.187,0,0,0-.844,1.933,8.8,8.8,0,0,0-.273,2.164,9.33,9.33,0,0,0,.258,2.179,5.81,5.81,0,0,0,.83,1.906,4.28,4.28,0,0,0,1.47,1.348,4.393,4.393,0,0,0,2.179.5A4.167,4.167,0,0,0,428.9,46.1a4.681,4.681,0,0,0,1.4-2.777h1.742a8.165,8.165,0,0,1-.695,2.138,5.63,5.63,0,0,1-1.226,1.66A5.254,5.254,0,0,1,428.34,48.2a7.64,7.64,0,0,1-5.283-.191,5.48,5.48,0,0,1-2.015-1.566,6.6,6.6,0,0,1-1.17-2.355,10.725,10.725,0,0,1-.381-2.914,9.354,9.354,0,0,1,.422-2.818,7.383,7.383,0,0,1,1.238-2.382,6.009,6.009,0,0,1,2.029-1.648,6.109,6.109,0,0,1,2.791-.613,6.3,6.3,0,0,1,3.962,1.17,5.432,5.432,0,0,1,1.892,3.648h-1.66Z" transform="translate(-353.408 -28.4)" fill="#fff"/>
                <path id="Path_25" data-name="Path 25" d="M516.63,29.32q-.3.054-.789.191a4.038,4.038,0,0,1-1.089.135,2.6,2.6,0,0,1-1.865-.613,2.98,2.98,0,0,1-.64-2.191V16.523H510.26V15.162h1.988V11.24h1.633v3.921h2.642v1.361h-2.642v9.666c0,.345.009.644.027.9a1.486,1.486,0,0,0,.164.627.782.782,0,0,0,.422.354,2.345,2.345,0,0,0,.8.109,4.043,4.043,0,0,0,.666-.055c.227-.036.449-.082.666-.135v1.334Z" transform="translate(-429.879 -9.469)" fill="#fff"/>
                <path id="Path_26" data-name="Path 26" d="M578.051.08V3.165c-.213-.027-.57-.047-1.07-.06a1.233,1.233,0,0,0-1.036.332,1.206,1.206,0,0,0-.285.778V5.438h2.486V8.109H575.66V19.8h-3.776V8.109H569.77V5.438h2.075V4.507a4.894,4.894,0,0,1,.785-3.2Q573.454,0,576.6,0c.239,0,.457.006.652.02s.46.033.8.06Z" transform="translate(-480.015)" fill="#fff"/>
                <path id="Path_27" data-name="Path 27" d="M642.377,41.852a4.805,4.805,0,0,1-1.344,2.62,6.578,6.578,0,0,1-5.211,2.114,7.346,7.346,0,0,1-4.879-1.782q-2.115-1.782-2.114-5.8a8.061,8.061,0,0,1,1.908-5.77,6.519,6.519,0,0,1,4.953-2.007,7.6,7.6,0,0,1,3.258.677,5.7,5.7,0,0,1,2.393,2.141,7.314,7.314,0,0,1,1.1,2.991,19.37,19.37,0,0,1,.12,2.872h-9.918a3.6,3.6,0,0,0,1.369,3.058,3.22,3.22,0,0,0,1.887.545,2.722,2.722,0,0,0,1.9-.665,3.145,3.145,0,0,0,.7-1Zm-3.749-4.441a3.293,3.293,0,0,0-.911-2.279,2.829,2.829,0,0,0-2.027-.778,2.575,2.575,0,0,0-2.042.824,4.116,4.116,0,0,0-.91,2.234Z" transform="translate(-529.772 -26.311)" fill="#fff"/>
                <path id="Path_28" data-name="Path 28" d="M737.817,41.852a4.8,4.8,0,0,1-1.344,2.62,6.578,6.578,0,0,1-5.211,2.114,7.346,7.346,0,0,1-4.878-1.782q-2.115-1.782-2.114-5.8a8.062,8.062,0,0,1,1.908-5.77,6.519,6.519,0,0,1,4.953-2.007,7.6,7.6,0,0,1,3.258.677,5.7,5.7,0,0,1,2.393,2.141,7.312,7.312,0,0,1,1.1,2.991,19.344,19.344,0,0,1,.12,2.872h-9.918a3.6,3.6,0,0,0,1.369,3.058,3.225,3.225,0,0,0,1.889.545,2.721,2.721,0,0,0,1.9-.665,3.144,3.144,0,0,0,.7-1h3.869Zm-3.749-4.441a3.293,3.293,0,0,0-.91-2.279,2.829,2.829,0,0,0-2.027-.778,2.575,2.575,0,0,0-2.041.824,4.115,4.115,0,0,0-.911,2.234Z" transform="translate(-610.178 -26.311)" fill="#fff"/>
                <path id="Path_29" data-name="Path 29" d="M835.187,1.43V21H831.5V18.994a5.329,5.329,0,0,1-1.848,1.875,5.2,5.2,0,0,1-2.579.584A5.384,5.384,0,0,1,822.8,19.4a7.889,7.889,0,0,1-1.734-5.271,9.062,9.062,0,0,1,1.709-5.836,5.558,5.558,0,0,1,4.567-2.127,4.666,4.666,0,0,1,2.339.578,4.612,4.612,0,0,1,1.662,1.6V1.43h3.842ZM824.977,13.847a5.7,5.7,0,0,0,.8,3.2,2.655,2.655,0,0,0,2.393,1.21,2.8,2.8,0,0,0,2.446-1.2,5.28,5.28,0,0,0,.838-3.1,4.754,4.754,0,0,0-1.342-3.8,2.9,2.9,0,0,0-1.914-.692,2.662,2.662,0,0,0-2.44,1.257,5.823,5.823,0,0,0-.778,3.117Z" transform="translate(-691.73 -1.205)" fill="#fff"/>
                <path id="Path_30" data-name="Path 30" d="M939.014,21.387a5.167,5.167,0,0,1-2.884-.718,5.762,5.762,0,0,1-1.41-1.489V21h-3.71V1.43h3.776V8.4a5.515,5.515,0,0,1,1.581-1.542,4.7,4.7,0,0,1,2.605-.665,5.368,5.368,0,0,1,4.474,2.06,8.363,8.363,0,0,1,1.615,5.318,9.521,9.521,0,0,1-1.6,5.6,5.171,5.171,0,0,1-4.453,2.219Zm2.075-7.418a7,7,0,0,0-.4-2.552A2.779,2.779,0,0,0,937.9,9.5a2.836,2.836,0,0,0-2.832,1.875,7.029,7.029,0,0,0-.4,2.579,5.4,5.4,0,0,0,.824,3.084A2.821,2.821,0,0,0,938,18.263a2.6,2.6,0,0,0,2.273-1.183,5.406,5.406,0,0,0,.811-3.111Z" transform="translate(-784.352 -1.205)" fill="#fff"/>
                <path id="Path_31" data-name="Path 31" d="M1032.029,33.394q1.5-1.914,5.159-1.914a9.175,9.175,0,0,1,4.228.944,3.626,3.626,0,0,1,1.848,3.563v6.647q0,.692.027,1.674a2.1,2.1,0,0,0,.227,1.01,1.371,1.371,0,0,0,.558.439v.558h-4.121a4.24,4.24,0,0,1-.239-.824q-.066-.385-.107-.877a7.8,7.8,0,0,1-1.808,1.449,5.445,5.445,0,0,1-2.766.7,4.759,4.759,0,0,1-3.251-1.123,4,4,0,0,1-1.282-3.184,4.132,4.132,0,0,1,2.06-3.869,9.137,9.137,0,0,1,3.324-.931l1.29-.159a5.932,5.932,0,0,0,1.5-.332,1.139,1.139,0,0,0,.811-1.077,1.279,1.279,0,0,0-.619-1.23,3.875,3.875,0,0,0-1.815-.339,2.366,2.366,0,0,0-1.9.665,2.771,2.771,0,0,0-.532,1.329h-3.656a5.565,5.565,0,0,1,1.063-3.124Zm2.818,10.184a1.978,1.978,0,0,0,1.3.439,3.862,3.862,0,0,0,2.254-.718,3.059,3.059,0,0,0,1.07-2.62v-1.41a4.065,4.065,0,0,1-.725.365,6.131,6.131,0,0,1-1,.26l-.851.159a5.531,5.531,0,0,0-1.715.518,1.738,1.738,0,0,0-.877,1.608,1.661,1.661,0,0,0,.545,1.4Z" transform="translate(-868.17 -26.521)" fill="#fff"/>
                <path id="Path_32" data-name="Path 32" d="M1140.313,37.025h-3.881a3.349,3.349,0,0,0-.545-1.462,2.286,2.286,0,0,0-1.981-.877,2.61,2.61,0,0,0-2.62,1.9,7.975,7.975,0,0,0-.372,2.686,7.131,7.131,0,0,0,.372,2.566,2.528,2.528,0,0,0,2.552,1.808,2.252,2.252,0,0,0,1.889-.718,3.559,3.559,0,0,0,.677-1.862h3.869a6.336,6.336,0,0,1-1.249,3.27,6.076,6.076,0,0,1-5.279,2.486,6.171,6.171,0,0,1-5.145-2.075,8.389,8.389,0,0,1-1.649-5.384,8.511,8.511,0,0,1,1.821-5.809,6.339,6.339,0,0,1,5.025-2.075,7.58,7.58,0,0,1,4.461,1.222q1.734,1.224,2.054,4.321Z" transform="translate(-949.427 -26.521)" fill="#fff"/>
                <path id="Path_33" data-name="Path 33" d="M1240.714,21.22h-4.573l-3.47-6.2-1.569,1.635v4.56h-3.722V1.69h3.722V12.246l4.719-5.45h4.693l-5.066,5.543,5.265,8.881Z" transform="translate(-1034.037 -1.424)" fill="#fff"/>
            </g>
        </g>
    </svg>;
}

export default SentactFeedbackLogo;
