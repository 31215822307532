import React from 'react';
import { Page } from "Components";
import { SyncLoader } from "react-spinners";

const LoadingPage = ({}) => {
    return <Page className={`flex flex-col h-full items-center justify-around`}>
        <SyncLoader
            color="white"
            margin={10}
            size={20}
            speedMultiplier={0.75}
        />
    </Page>
};

export default LoadingPage;
