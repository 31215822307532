import React from 'react';
import {
    EmojiAngry,
    EmojiFrown,
    EmojiNeutral,
    EmojiSmile,
    EmojiLaughing,
    Star,
    StarFill,
    XCircleFill
} from 'react-bootstrap-icons';
import IconTypeEnum from "Enum/IconTypeEnum.js";

export const iconTextDictionary = {
    [IconTypeEnum.FACE_VERY_UNHAPPY]: 'Very Bad',
    [IconTypeEnum.FACE_UNHAPPY]: 'Bad',
    [IconTypeEnum.FACE_NEUTRAL]: 'Neutral',
    [IconTypeEnum.FACE_HAPPY]: 'Good',
    [IconTypeEnum.FACE_VERY_HAPPY]: 'Very Good'
};

export const Icon = ({ type, selected=false, ...rest }) => {
    switch (type) {
        case IconTypeEnum.STAR:
            return selected ? <StarFill {...rest} /> : <Star {...rest} />;
        case IconTypeEnum.FACE_VERY_UNHAPPY:
            return <EmojiAngry {...rest} />;
        case IconTypeEnum.FACE_UNHAPPY:
            return <EmojiFrown {...rest} />;
        case IconTypeEnum.FACE_NEUTRAL:
            return <EmojiNeutral {...rest} />;
        case IconTypeEnum.FACE_HAPPY:
            return <EmojiSmile {...rest} />;
        case IconTypeEnum.FACE_VERY_HAPPY:
            return <EmojiLaughing {...rest} />;
        case IconTypeEnum.EXIT:
            return <XCircleFill {...rest} />;
        default:
            return <div className={`w-full text-center text-base`} {...rest}>{type}</div>
    }
};
