import axios from 'axios';

/**
 * Sentact Public API base url
 */
const publicApiBaseUrl = 'api/';

/**
 * The cancelTokenFactory will generate a unique token and unique method that can be called anywhere to cancel
 * a request bearing the matched token. Name your variables returned from `source()` to be descriptive.
 *
 * e.g. (simplified):
 *
 *      const { cancel, token } = cancelTokenFactory.source();
 *
 *      let data = await axiosCall(...params, token);
 *
 *      if (condition) {
 *          cancel('Request was cancelled for reasons known only to Product team.');
 *      } else {
 *          setState({...data});
 *      }
 */
const cancelTokenFactory = axios.CancelToken;

/**
 * Send a GET request
 *
 * @param url           Url to hit
 * @param params        Request params
 * @param cancelToken   Reference token so axios can cancel a specific request if needed
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const get = (
    url,
    params={},
    cancelToken=null
) => {
    return axios.get(url, {
        params: params,
        cancelToken: cancelToken,
    }).then(response => {
        return response.data;
    }).catch(error => {
        throw new Error(error.message);
    });
};

/**
 * Send a POST request
 *
 * @param url           Url to hit
 * @param data          Data to send
 * @param params        Request params
 * @param cancelToken   Reference token so axios can cancel a specific request if needed
 *
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
const post = (
    url,
    data,
    params={},
    cancelToken=null
) => {
    return axios.post(url, data, {
        params: params,
        cancelToken: cancelToken,
    }).then(response => {
        return response;
    }).catch(error => {
        throw new Error(error);
    });
};

const base = {
    get: get,
    post: post,
    publicApiBaseUrl: publicApiBaseUrl,
    cancelTokenFactory: cancelTokenFactory
};

export default base;
