import React from 'react';
import SentactFeedbackLogo from "Images/SentactFeedbackLogo.jsx";

const SentactFeedbackStamp = ({}) => {
    return <div className={`p-3 text-center flex flex-col gap-1`}>
        <span className={`text-white text-opacity-30`}>powered by</span>
        <span className={`m-auto`}><SentactFeedbackLogo /></span>
    </div>;
};

export default SentactFeedbackStamp;
